<template>
  <v-container id="user-roles-table" fluid tag="section">
    <base-material-card icon="mdi-cart" :title="$t('presales.table.title')">
      <presales-form ref="editForm" :countries="countries" @itemSaved="onSaved"></presales-form>
      <payment-form ref="paymentForm" @onPaymentSaved="onPaymentSaved"></payment-form>
      <payment-dialog ref="paymentDialog"></payment-dialog>
      <delete-dialog
        ref="deleteConfirm"
        :url="apiQuery"
        :title="$t('presales.delete presale')"
        @itemDeleted="onDeleted"
      >
        <template v-slot:default="item">
          Are you sure you want to delete presale with id
          <strong>{{ item.id }}</strong> ?
        </template>

        <template v-slot:error="error">
          <v-alert v-if="error.message" tile class="mb-0" type="error">{{ error.message }}</v-alert>
        </template>

        <template v-slot:confirmed="item">
          <v-alert v-if="item.id" tile type="success" class="mb-0">Price {{ item.id }} deleted</v-alert>
        </template>
      </delete-dialog>
      <v-data-table
        :headers="headers"
        :items="items"
        :options.sync="options"
        :server-items-length="total"
        :loading="loading"
        multi-sort
        :footer-props="{
          'items-per-page-options': [10, 20, 50],
        }"
      >
        <template v-slot:item.id="{ item }">
          <v-chip v-if="item.isNew" color="green">{{ item.id }}</v-chip>
          <span v-else>{{ item.id }}</span>
        </template>

        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-spacer />
            <v-btn color="primary" dark class="mb-2" @click="onAdd">
              <v-icon left>mdi-cart-plus</v-icon>
              {{ $t('presales.add presale') }}
            </v-btn>
          </v-toolbar>
          <v-row>
            <v-col cols="4">
              <v-text-field 
                v-model="name" 
                @change="onChangeFilter" 
                clearable
                name="name"
                label="Name"></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field 
                v-model="email" 
                @change="onChangeFilter" 
                clearable 
                name="email"
                label="Email"
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-autocomplete
                v-model="country"
                @change="onChangeFilter"
                :items="countriesList"
                clearable
                name="country"
                label="Country"
              ></v-autocomplete>
            </v-col>
          </v-row>
        </template>
        <template v-slot:item.payment="{ item }">
          <v-icon v-if="item.payment !== null" small @click="onPaymentShow(item)">mdi-credit-card-outline</v-icon>
          <v-icon v-else small @click="onPaymentAdd(item)">mdi-credit-card-plus-outline</v-icon>
        </template>
        <template v-slot:item.created_at="{ item }">
          <span>{{new Date(item.created_at).toLocaleString()}}</span>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon small @click="onDelete(item)">mdi-delete</v-icon>
        </template>
      </v-data-table>
    </base-material-card>
  </v-container>
</template>

<script>
import crudMixin from 'mixins/crudTable'
import PresalesForm from '../forms/PresalesForm'
import PaymentForm from '../forms/PaymentForm'
import PaymentDialog from '../dialogs/PaymentDialog'
import DeleteDialog from '@/components/crud/DeleteDialog'

export default {
  components: {
    PresalesForm,
    DeleteDialog,
    PaymentForm,
    PaymentDialog
  },
  mixins: [crudMixin],
  data() {
    return {
      apiQuery: '/api/presales/',
      name: null,
      email: null,
      country: null,
      countries: [],
      headers: [
        { text: 'ID', align: 'start', value: 'id' },
        { text: this.$t('presales.table.name'), value: 'name' },
        { text: this.$t('presales.table.email'), value: 'email'},
        { text: this.$t('presales.table.country'), value: 'country.name' },
        { text: this.$t('presales.table.amount'), value: 'amount' },
        { text: this.$t('presales.table.price'), value: 'price' },
        { text: this.$t('presales.table.payment'), value: 'payment' },
        { text: this.$t('presales.table.date'), value: 'created_at' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
    }
  },
  created() {
    this.get('/api/countries').then((data) => {
        this.countries = data
    })
  },
  mounted() {},
  computed:{
    countriesList(){
      return this.countries.map((el) => el.name)
    }
  },
  methods: {
    onPaymentAdd(item) {
      this.$refs.paymentForm.addPayment(item, this.items.indexOf(item))
    },
    onPaymentShow(item) {
      this.$refs.paymentDialog.showPayment(item)
    },
    onChangeFilter(){
      let filters = {};
      if(this.name !== null){
          filters['name'] = this.name
      }
      if(this.email !== null){
          filters['email'] = this.email
      }
      if(this.country !== null){
          filters['country'] = this.country
      }
      this.filters = filters
    },
    onPaymentSaved(item, index){
      this.items[index].payment = item
      window.setTimeout(() => {
        this.$refs.paymentForm.closeForm()
      }, 2500)
    },
    onSaved(item, index) {
      if (index === -1) {
        this.total++
        item.isNew = true
        this.items.push(item)
        window.setTimeout(() => {
          this.$refs.editForm.clearForm()
        }, 1500)
      } else {
        Object.assign(this.items[index], item)
        window.setTimeout(() => {
          this.$refs.editForm.closeForm()
        }, 2500)
      }
    },
  },
}
</script>
