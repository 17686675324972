<template>
  <div id="main-content-presale-prices">
    <presalesTable />
  </div>
</template>

<script>
import presalesTable from './tables/PresalesTable'
export default {
    name: 'PresalePrices',
    components: {
      presalesTable
    }
}
</script>