<template>
  <v-dialog v-model="showFormDialog" max-width="50vw" @click:outside="closeForm">
    <v-card>
      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>
      <v-form ref="form" v-model="valid" @submit.prevent="saveItem">
        <v-card-text>
          <v-container>
            <v-text-field
              v-model="payment.transaction_id"
              prepend-icon="mdi-table"
              label="Transaction id"
            ></v-text-field>
            <v-select
              v-model="payment.status"
              :items="paymentStatus"
              prepend-icon="mdi-credit-card-check-outline"
              label="Status"
            ></v-select>
            <v-text-field v-model="payment.value" prepend-icon="mdi-cash-multiple" label="Value"></v-text-field>
            <v-text-field v-model="payment.from" prepend-icon="mdi-bank" label="From"></v-text-field>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="gray"
            :disabled="sending || savedItem != null"
            @click="closeForm"
          >{{ $t('Cancel') }}</v-btn>
          <v-btn
            type="submit"
            :disabled="sending || savedItem != null"
            color="primary"
            @click="savePayemnt"
          >{{ $t('Save') }}</v-btn>
        </v-card-actions>
      </v-form>
      <v-progress-linear
        v-if="sending"
        color="deep-purple accent-4"
        indeterminate
        rounded
        height="6"
      ></v-progress-linear>
      <v-alert v-if="errorMessage" tile class="mb-0" type="error">
        {{
        errorMessage
        }}
      </v-alert>
      <v-alert
        v-if="savedItem"
        tile
        type="success"
        class="mb-0"
      >The payment {{ savedItem.id }} was saved with success!</v-alert>
    </v-card>
  </v-dialog>
</template>

<script>
import crudMixin from 'mixins/crudForm'

export default {
  mixins: [crudMixin],
  props: {
    countries: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      apiQuery: '/api/payments/',
      payment: {},
      presaleIndex: -1,
      paymentStatus: ['Requested', 'Declined', 'Pending', 'Canceled', 'Confirmed']
    }
  },
  computed: {
    countriesData(){
      let countriesFormed = []
      for(let index in this.countries){
        countriesFormed.push({'value': this.countries[index].id, 'text': this.countries[index].name})
      }
      return countriesFormed
    },
    formTitle() {
      return this.$t('presales.add payment')
    }
  },
  methods: {
    clearForm () {
      this.errorMessage = null
      this.serverErrors = null
      this.payment = Object.assign({}, this.defaultItem)
    },
    addPayment (item, index) {
      this.payment.presale_id = item.id
      this.presaleIndex = index
      this.showFormDialog = true
    },
    savePayemnt () {
      this.errorMessage = null
      this.serverErrors = null
      if (!this.$refs.form.validate()) {
        return
      }
      this.post(this.apiQuery, this.payment)
        .then(res => {
          this.savedItem = res
          this.$emit('onPaymentSaved', res, this.presaleIndex)
        })
        .catch(error => {
          this.errorMessage = error.message
          this.serverErrors = error.errors
          this.$refs.form.validate()
          this.$emit('serverError', error)
        })
    },
  },
}
</script>
