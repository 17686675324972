/**
 * This mixin implements CRUD using API calls to Laravel
 */
export default {
  data () {
    return {
      showDialog: false,
      errorMessage: null,
      itemToShow: null,
      item: {},
      reading: false
    }
  },

  methods: {

    closeForm () {
      this.showDialog = false
    },

    showItem () {
      if (this.itemToShow != null) {
        this.reading = true
        this.show(this.apiQuery, this.itemToShow.id)
          .then((data) => {
            this.item = data
            this.reading= false
          }).catch(error => {
            this.errorMessage = error.message
            this.$emit('serverError', error)
          })
      }
    },
  },
}
