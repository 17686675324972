<template>
  <v-dialog v-model="showDialog" max-width="50vw" @click:outside="closeForm">
    <v-card>
      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-progress-linear
            v-if="reading"
            color="deep-purple accent-4"
            indeterminate
            rounded
            height="6"
          ></v-progress-linear>
          <v-flex v-else>
            <v-text-field
              readonly
              v-model="item.transaction_id"
              prepend-icon="mdi-table"
              label="Transaction id"
            ></v-text-field>
            <v-text-field
              readonly
              v-model="item.status"
              prepend-icon="mdi-credit-card-check-outline"
              label="Status"
            ></v-text-field>
            <v-text-field 
              readonly 
              v-model="item.value" 
              prepend-icon="mdi-cash-multiple" 
              label="Value"
            ></v-text-field>
            <v-text-field 
              readonly 
              v-model="item.from" 
              prepend-icon="mdi-bank"
              label="From"
            ></v-text-field>
          </v-flex>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="gray"
          @click="closeForm"
        >{{ $t('Close') }}</v-btn>
      </v-card-actions>
      <v-alert v-if="errorMessage" tile class="mb-0" type="error">
        {{
        errorMessage
        }}
      </v-alert>
    </v-card>
  </v-dialog>
</template>

<script>
import crudMixin from 'mixins/crudDetails'

export default {
  mixins: [crudMixin],
  data() {
    return {
      apiQuery: '/api/payments/'
    }
  },
  computed: {
    formTitle() {
      return this.$t('presales.payment details')
    }
  },
  methods: {
    showPayment(item){
      this.showDialog = true
      this.itemToShow = item.payment
      this.showItem()
    }
  },
}
</script>
