<template>
  <v-dialog v-model="showFormDialog" max-width="50vw" @click:outside="closeForm">
    <v-card>
      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>
      <v-form ref="form" v-model="valid" @submit.prevent="saveItem">
        <v-card-text>
          <v-container>
            <v-text-field v-model="form.name" prepend-icon="person" label="Buyer name"></v-text-field>
            <v-text-field
              v-model="form.email"
              prepend-icon="email"
              label="Buyer email"
              :rules="rules.email"
            ></v-text-field>
            <v-autocomplete
              v-model="form.country"
              :items="countriesData"
              prepend-icon="mdi-earth"
              label="Country"
            ></v-autocomplete>
            <v-text-field
              v-model="form.amount"
              prepend-icon="mdi-cart-arrow-down"
              label="Amount"
              :rules="rules.amount"
            ></v-text-field>
            <v-text-field
              v-model="form.price"
              prepend-icon="mdi-cash-multiple"
              label="Price"
              :rules="rules.price"
            ></v-text-field>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="gray"
            :disabled="sending || savedItem != null"
            @click="closeForm"
          >{{ $t('Cancel') }}</v-btn>
          <v-btn
            type="submit"
            :disabled="sending || savedItem != null"
            color="primary"
          >{{ $t('Save') }}</v-btn>
        </v-card-actions>
      </v-form>
      <v-progress-linear
        v-if="sending"
        color="deep-purple accent-4"
        indeterminate
        rounded
        height="6"
      ></v-progress-linear>
      <v-alert v-if="errorMessage" tile class="mb-0" type="error">
        {{
        errorMessage
        }}
      </v-alert>
      <v-alert
        v-if="savedItem"
        tile
        type="success"
        class="mb-0"
      >The presale {{ savedItem.id }} was saved with success!</v-alert>
    </v-card>
  </v-dialog>
</template>

<script>
import crudMixin from 'mixins/crudForm'
import validate from '@/plugins/validate'

export default {
  mixins: [crudMixin],
  props: {
    countries: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      apiQuery: '/api/presales/',
      menuFrom: false,
      menuTo: false,
      rules: {
        price: [
          v => !!v || 'Required.',
          () => this.serverErrors && this.serverErrors.price ? this.$t('presales.price.'+ this.serverErrors.price[0]) : true,
        ],
        amount: [
          v => !!v || 'Required.',
          () => this.serverErrors && this.serverErrors.amount ? this.$t('presales.amount.'+this.serverErrors.amount[0]) : true,
        ],
        name: [
          v => !!v || 'Required.',
        ],
        email: [
          v => !!v || 'Required.',
          v => {
            return validate.email.test(v) || 'Invalid e-mail.'
          },
          () => this.serverErrors && this.serverErrors.email ? this.serverErrors.email[0] : true,
        ],
      },
    }
  },
  computed: {
    countriesData(){
      let countriesFormed = []
      for(let index in this.countries){
        countriesFormed.push({'value': this.countries[index].id, 'text': this.countries[index].name})
      }
      return countriesFormed
    },
    formTitle() {
      return this.editedItem === null
        ? this.$t('presales.add presale')
        : this.$t('presales.edit presale')
    }
  },
  methods: {},
}
</script>
